import axios from "axios";
import { useState } from "react";
import { InstaCopiesCard } from "../compoment/CopiesCard";
import { getAuthHeader } from "../utils";
export function CopyMaker(props) {
  const [inputText, setInputText] = useState("");
  const [sex, setSex] = useState("male");
  const [lang, setLang] = useState("kor");
  const [copies, setCopies] = useState(null);
  const { cid } = props;

  const onChangeText = (e) => {
    setInputText(e.target.value);
  };

  const onSummitClick = (e) => {
    e.preventDefault();
    const header = getAuthHeader();

    axios
      .post(
        `/predicts/${cid}`,
        {
          text: inputText,
        },
        {
          headers: header,
        }
      )
      .then((res) => {
        setCopies(res.data);
        // props.setSummited(true);
      });
    props.loadCredits(0);
  };

  return (
    <div>
      {
        <form className="copy_input_form">
          <div className="inputArea">
            <div className="iL">
              <div className="stepBox">
                <p className="step_num active">1</p>
                <div className="con">
                  <p className="tit">언어</p>
                  <ul className="optList">
                    <li>
                      <div className="input_ic_opt">
                        <input
                          id="l1"
                          type="radio"
                          checked={lang === "kor" ? true : false}
                          onClick={(e) => {
                            setLang("kor");
                          }}
                        />
                        <label htmlFor="l1">한글</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_ic_opt">
                        <input
                          id="l2"
                          type="radio"
                          checked={lang === "en" ? true : false}
                          onClick={(e) => {
                            setLang("en");
                          }}
                        />
                        <label htmlFor="l2">영어</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="stepBox">
                <p className="step_num">2</p>
                <div className="con">
                  <p className="tit">타겟</p>
                  <ul className="optList">
                    <li>
                      <div className="input_opt">
                        <input
                          type="checkbox"
                          checked={sex === "male" ? true : false}
                          onChange={(e) => {
                            setSex("male");
                          }}
                        />
                        <label htmlFor="">남자</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_opt">
                        <input
                          type="checkbox"
                          checked={sex === "female" ? true : false}
                          onChange={(e) => {
                            console.log("1312");
                            setSex("female");
                          }}
                        />
                        <label htmlFor="">여자</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_opt">
                        <input type="checkbox" />
                        <label htmlFor="">모두</label>
                      </div>
                    </li>
                  </ul>
                  <ul className="optList">
                    <li>
                      <div className="input_opt">
                        <input type="checkbox" />
                        <label htmlFor="">10대</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_opt">
                        <input type="checkbox" />
                        <label htmlFor="">20대</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_opt">
                        <input type="checkbox" />
                        <label htmlFor="">30대</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_opt">
                        <input type="checkbox" />
                        <label htmlFor="">40대</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_opt">
                        <input type="checkbox" />
                        <label htmlFor="">50대</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="stepBox">
                <p className="step_num">3</p>
                <div className="con">
                  <p className="tit">스타일</p>
                  <ul className="optList">
                    <li>
                      <div className="input_ic_opt">
                        <input id="" type="radio" />
                        <label htmlFor="">친숙한</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_ic_opt">
                        <input id="" type="radio" />
                        <label htmlFor="">재미있는</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_ic_opt">
                        <input id="" type="radio" />
                        <label htmlFor="">일반적인</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_ic_opt">
                        <input id="" type="radio" />
                        <label htmlFor="">전문적인</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_ic_opt">
                        <input id="" type="radio" />
                        <label htmlFor="">슬픈</label>
                      </div>
                    </li>
                    <li>
                      <div className="input_ic_opt">
                        <input id="" type="radio" />
                        <label htmlFor="">재치있는</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="iR">
              <div className="stepBox h100p">
                <p className="step_num last">4</p>
                <div className="con">
                  <p className="tit">설명</p>
                  <textarea
                    className="h100p"
                    placeholder="광고 문구에 대한 생각을 최대한 자세하게 적어주세요.&#13;&#10;&#13;&#10;ex.&#13;&#10;- 아이스크림&#13;&#10;- 딸기맛 / 초코맛 / 바닐라맛&#13;&#10;- 세상에 없는 소프트콘&#13;&#10;- 여름에 먹는 가장 좋은 간식"
                    onChange={onChangeText}
                    value={inputText}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="btnArea">
            <button
              className="btn_common_m_g1"
              type="reset"
              onClick={(e) => setInputText("")}
            >
              입력 초기화
            </button>
            <button
              className="btn_common_m_point"
              type="submit"
              onClick={onSummitClick}
            >
              광고 생성
            </button>
          </div>
        </form>
      }

      <div className="copy_gen_wrap">
        <div className="copyList">
          {copies ? (
            copies.map((item, index) => {
              return (
                <InstaCopiesCard
                  cid={props.cid}
                  users={item.email}
                  prof_img={item.thumbnail}
                  ad_img={item.copy_img}
                  key={index}
                  ctime={item.c_date}
                  liked={false}
                  likeCount={item.comment_count}
                  commentCount={item.comment_count}
                  copy={item.copy_content}
                  switchMemu={props.switchMemu}
                  setSummited={props.setSummited}
                  loadData={props.loadData}
                  mode=" "
                />
              );
            })
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
}
