import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { InstaCopiesCard as InstagramCopiesCard } from "../compoment/CopiesCard";
import { CopyMaker } from "./CopyMaker";
import { SideInfoBox } from "./Side";

import { getAuthHeader } from "../utils";

const withParams = (Child) => {
  return (props) => {
    const params = useParams();
    return <Child {...props} params={params} />;
  };
};

class Copies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      menuIndex_1: "on",
      menuIndex_2: "",
      menuIndex_3: "",
    };
  }

  switchMemu = (index) => {
    if (index === 1) {
      this.setState({
        data: this.state.data,
        menuIndex_1: "on",
        menuIndex_2: "",
        menuIndex_3: "",
      });
    } else if (index === 2) {
      this.setState({
        data: this.state.data,
        menuIndex_1: "",
        menuIndex_2: "on",
        menuIndex_3: "",
      });
    } else if (index === 3) {
      this.setState({
        data: this.state.data,
        menuIndex_1: "",
        menuIndex_2: "",
        menuIndex_3: "on",
      });
    }
  };

  loadData = (e) => {
    axios
      .get(`/challenges/${this.props.params.id}`, {
        headers: getAuthHeader(),
      })
      .then((res) => {
        const data = res.data;
        const copies = data.copies;
        this.setState({
          ...this.state,
          data: res.data,
          copies: copies,
        });
        this.props.setSummited(res.data.summit);
      });
  };

  componentDidMount() {
    this.loadData(0);
  }

  render() {
    // 변경해야함 공유가능하도록
    const { data, menuIndex_1, menuIndex_2, menuIndex_3 } = this.state;
    if (data == null) {
      return <div />;
    }
    const copies = data.copies;
    return (
      <div>
        <section id="container">
          <section className="detail_wrap">
            <main>
              <div className="company">
                <div className="logo">
                  <img src={data.provider_logo} alt="" />
                </div>
                <p>{data.provider_name}</p>
                <button className="btn_bookmark on" type="button">
                  즐겨찾기 추가
                </button>
              </div>

              <h1 className="tit">{data.memo}</h1>

              <ul className="tab_menu">
                <li>
                  <button
                    className={menuIndex_1}
                    data-tab="tab-1"
                    type="button"
                    onClick={(e) => this.switchMemu(1)}
                  >
                    설명
                  </button>
                </li>
                <li>
                  <button
                    className={menuIndex_2}
                    data-tab="tab-2"
                    type="button"
                    onClick={(e) => this.switchMemu(2)}
                  >
                    광고 카피 리스트
                  </button>
                </li>
                <li>
                  {this.props.summited ? (
                    <div />
                  ) : (
                    <button
                      className={menuIndex_3}
                      data-tab="tab-3"
                      type="button"
                      onClick={(e) => this.switchMemu(3)}
                      disabled={this.props.summited}
                    >
                      광고 카피 만들기
                    </button>
                  )}
                </li>
              </ul>

              <div id="tab-1" className={"tab_content " + menuIndex_1}>
                <img src={data.sub_memo} alt="sub_memo.png" />
              </div>

              <div id="tab-2" className={"tab_content " + menuIndex_2}>
                <p className="txt_notice">
                  {
                    "※ 최종 카피 선택시 도움이 될 수 있도록 좋다고 생각되는 광고 카피에 댓글과 투표 부탁 드립니다."
                  }
                </p>
                <div className="copyList">
                  {copies.map((item, index) => {
                    return (
                      <InstagramCopiesCard
                        users={item.email}
                        prof_img={item.thumbnail}
                        ad_img={item.copy_img}
                        key={index}
                        ctime={item.c_date}
                        liked={false}
                        likeCount={100}
                        commentCount={item.comment_count}
                        copy={item.copy_content}
                      />
                    );
                  })}
                </div>
              </div>

              <div id="tab-3" className={"tab_content " + menuIndex_3}>
                <CopyMaker
                  cid={this.props.params.id}
                  summited={this.props.summited}
                  setSummited={this.props.setSummited}
                  switchMemu={this.switchMemu}
                  loadData={this.loadData}
                  loadCredits={this.props.loadCredits}
                />
              </div>
            </main>

            <aside>
              {/* TODO: ex expire date */}
              <SideInfoBox
                cid={this.props.params.id}
                userCount={data.user_count}
                budget={data.budget}
                switchMemu={this.switchMemu}
                summited={this.props.summited}
              />
            </aside>
          </section>
        </section>
      </div>
    );
  }
}

export default withParams(Copies);
