import { InstaChallengeCard } from "../compoment/ChallengeCard";

function Challenges(props) {
  return (
    <section className="cBox_list">
      <section className="titBox">
        <div className="txtArea">
          <p className="tit">
            안녕하세요,<b> {props.user} </b>님!
          </p>
          <p className="subTxt">
            번뜩이는 아이디어로 가장 좋은 결과물을 만들어 보세요!!{" "}
          </p>
        </div>
        <div className="sorting">
          <select className="sorting_select">
            <option value="">전체 플랫폼</option>
            <option value="">인스타그램</option>
            <option value="">유튜브</option>
            <option value="">네이버</option>
          </select>
          <select className="sorting_select">
            <option value="">진행 중</option>
            <option value="">심사 중</option>
            <option value="">완료</option>
          </select>
        </div>
      </section>

      <ul>
        {props.challenges.map((challenge, index) => {
          return (
            <InstaChallengeCard
              cid={challenge.cid}
              cardImg={challenge.image_url}
              logoImg={challenge.provider_logo}
              title={challenge.title}
              provider={challenge.provider_name}
              budget={challenge.budget}
              userCount={challenge.user_count}
              c_data={challenge.expire_date}
              platform={challenge.platform}
              dday={challenge.dday}
              key={index}
            />
          );
        })}
      </ul>
    </section>
  );
}

export default Challenges;
