import { useNavigate } from "react-router-dom";

import logo from "../images/h_logo_copick.svg";
function Header(props) {
  const navigate = useNavigate();
  const credits = props.credits

  return (
    <div id="header">
      <nav>
        <a href="/challenges" className="logo">
          <img src={logo} alt="" />
        </a>
        <ul className="nav_list">
          <li>
            <button className="on">광고</button>
          </li>
          <li>
            <button className="">소설</button>
          </li>
          <li>
            <button className="">아이디어</button>
          </li>
          <li>
            <button className="">마이페이지</button>
          </li>
        </ul>
        <ul className="nR_list">
          <li>
            <p className="myCredit">{credits.toLocaleString("kr-KR")}</p>
          </li>
          <li>
            <button
              className="btn_logout"
              type="button"
              onClick={(e) => {
                localStorage.clear()
                navigate("/");
              }}
            >
              로그아웃
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
