export function InstaChallengeCard(props) {
  return (
    <li>
      <a href={`/challenges/${props.cid}`} className="card">
        <div className="challenge_img">
          <img src={props.cardImg} alt="" />
        </div>
        <div className="content">
          <div className="company">
            <div className="logo">
              <img src={props.logoImg} alt="" />
            </div>
            <p>{props.provider}</p>
            <button className="btn_bookmark on" type="button">
              즐겨찾기 추가
            </button>
          </div>
          <h1 className="tit">{props.title}</h1>
          <div className="info">
            <p className="prize">{props.budget.toLocaleString("ko-KR")} 만원</p>
            <p className="user">
              {props.userCount.toLocaleString("ko-KR")} 명 참여
            </p>
          </div>
        </div>
        <div className="icoBox">
          <p className="d-dayBox active">D-{props.dday}</p>
          <i className="ic_sns instagram">인스타그램</i>
        </div>
      </a>
    </li>
  );
}
