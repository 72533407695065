import axios from "axios";
import React, { createContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../Common.css";
import Challenges from "./Challenges";
import Copies from "./Copies";
import Header from "./Header";

export const AppContext = createContext();

const withParams = (Child) => {
  return (props) => {
    const params = useParams();
    return <Child {...props} params={params} navigate={useNavigate()} />;
  };
};

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      challenges: [],
      summited: false,
      credits: "-",
    };
  }

  loadCredits = (e) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: token };
    axios
      .get("/auth/credits", {
        headers: headers,
      })
      .then((res) => {
        this.setState({ ...this.state, credits: res.data.credits });
      });
  };

  componentDidMount() {
    const token = localStorage.getItem("token");
    const headers = { Authorization: token };
    this.loadCredits(0);
    axios.get("/challenges", { headers: headers }).then((res) => {
      this.setState({ ...this.state, challenges: res.data });
    });

    this.setState({ ...this.state, summited: false });
  }

  setSummited = (summited) => {
    this.setState({ ...this.state, summited: summited });
  };

  render() {
    const email = localStorage.getItem("email");
    const credits = this.state.credits;

    return email ? (
      <AppContext.Provider value={{ email: email, credits: credits }}>
        <div>
          <section id="wrap">
            <Header credits={credits} />

            <section id="container">
              {this.props.params.id ? (
                <Copies
                  summited={this.state.summited}
                  setSummited={this.setSummited}
                  loadCredits={this.loadCredits}
                />
              ) : (
                <Challenges challenges={this.state.challenges} user={email} />
              )}
            </section>
          </section>
        </div>
      </AppContext.Provider>
    ) : (
      this.props.navigate("/")
    );
  }
}

export default withParams(Layout);
