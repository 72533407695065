import axios from "axios";
import { useEffect, useState } from "react";
import { CopiesFeedBox } from "../compoment/CopiesFeedBox";
import { getAuthHeader } from "../utils";

const poolingInterval = 5000;

export function SideInfoBox(props) {
  let dday = 1;
  const [feedCopies, setFeedCopies] = useState(null);
  /*
  expiredate
  bugit
  userCount
  summited
  done
  */

  const getFeedCopies = () => {
    axios
      .get(`/challenges/${props.cid}/copies?page=1&size=10`, {
        headers: getAuthHeader(),
      })
      .then((res) => {
        setFeedCopies(res.data.items);
      });
  };
  useEffect(() => {
    getFeedCopies();
    const timer = setInterval(() => { getFeedCopies(); }, poolingInterval);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      <section className="infoBox">
        <h2>{dday}일 남음</h2>
        <p className="date">~{props.expireDate}까지</p>

        <p className="warning">마감일까지 {dday}일 남았습니다!</p>

        <dl className="prize">
          <dt>광고비</dt>
          <dd>{props.budget.toLocaleString("ko-KR")}만원</dd>
        </dl>
        <dl className="user">
          <dt>참여자</dt>
          <dd>{props.userCount.toLocaleString("ko-KR")}명</dd>
        </dl>

        <button
          className={props.summited ? "" : "instagram"}
          type="button"
          disabled={props.summited ? true : false}
          onClick={(e) => {
            props.switchMemu(3);
          }}
        >
          {props.summited
            ? "광고 카피 만들기에 참여 하였습니다."
            : "광고 카피 만들기"}
        </button>
      </section>

      <section className="copy_list">
        {props.summited ? <h2>내가 만든 광고 카피</h2> : <div />}
        <ul></ul>
        <h2>실시간 광고카피</h2>
        <ul>
          {feedCopies === null ? (
            <div />
          ) : (
            feedCopies.map((item, index) => {
              return (
                <CopiesFeedBox
                  title={item.copy_content}
                  email={item.email}
                  likeCount={100}
                  c_date={item.c_date}
                  key={index}
                />
              );
            })
          )}
        </ul>
      </section>
    </div>
  );
}
