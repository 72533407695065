import axios from "axios";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout/Layout";
import Login from "./Login/Login";

function App() {
  axios.defaults.baseURL = "https://copick-api.designovel.com";
  axios.defaults.withCredentials = true;

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/challenges/" element={<Layout />} />
        <Route exact path="/challenges/:id" element={<Layout />} />
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
