import axios from "axios";
import { getAuthHeader } from "../utils";
export function InstaCopiesCard(props) {
  /*
    prof_img
    users
    ctime
    ad_img
    liked
    likeCount
    copy
    mode
  */

  const summit = (e) => {
    const header = getAuthHeader();
    axios.post(
      `/challenges/${props.cid}/copies`,
      { text: props.copy },
      {
        headers: header,
      }
    );
    alert("광고 카피가 저장되었습니다.");
    props.switchMemu(2);
    props.setSummited(true);
    props.loadData(0);
  };
  return (
    <div className="copyBox instagram">
      <div className="user">
        <div className="prof_img">
          <img src={props.prof_img} alt="" />
        </div>
        <div className="info">
          <p className="name">{props.users}</p>
          <p className="date">{props.ctime}</p>
        </div>
      </div>
      <div className="ad_img">
        <img src={props.ad_img} alt="ad_img.png" />
      </div>
      <div className="btnArea">
        <button
          className={"btn_like " + (props.liked ? "on" : "")}
          type="button"
        >
          {props.likeCount}
        </button>
        <button className="btn_comment ml3" type="button">
          {props.commentCount}
        </button>
        {props.mode ? (
          <i className="ic_bookmark mla" onClick={summit}></i>
        ) : (
          <div />
        )}
      </div>
      <p className="copy">{props.copy}</p>
    </div>
  );
}
