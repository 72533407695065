export function CopiesFeedBox(props) {
  const { title, email, likeCount, c_date } = props;
  return (
    <li className="copyBox">
      <p className="con">{title}</p>
      <div className="info">
        <p className="nick">{email}</p>
        <p className="like">{likeCount}</p>
        <p className="time">{`${15} 초 전`}</p>
      </div>
    </li>
  );
}
