import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Common.css";
import h_logo from "../images/h_logo_copick_w.svg";
import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onLoginBtnClick = (e) => {
    e.preventDefault();
    const payload = { email: email };
    axios
      .post("/auth/login", payload)
      .then((res) => {
        localStorage.setItem("email", email);
        localStorage.setItem("token", res.data.Authorization);
        navigate("/challenges");
      })
      .catch((e) => {
        alert(e);
      });
  };

  return (
    <div className="bg_login">
      <section id="wrap">
        <header className="login_header">
          <nav>
            <a href="index.html" className="logo">
              <img src={h_logo} />
            </a>
          </nav>
        </header>

        <section className="login_wrap">
          <section className="loginBox">
            <h1>로그인</h1>
            <form onSubmit={onLoginBtnClick}>
              <input
                className="email_input"
                type="email"
                placeholder="이메일 입력"
                value={email}
                onChange={onChange}
              />
            </form>
            <button
              className="btn_login"
              type="button"
              onClick={onLoginBtnClick}
            >
              로그인
            </button>
          </section>
        </section>
      </section>
    </div>
  );
}

export default Login;
